@charset "UTF-8";

/****************************/
/** Reset **/
/****************************/


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

strong {
  font-weight: bold;
}

#root {
  width: 100%;
}

body {
  color: #727272;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 1.5;
  background: #C9F2FF;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page-wrapper {
  width: 100%;
  padding: 0;
  background-color: #C9F2FF;
}

.login-wrapper {
  display:flex;
  align-items: center;
  justify-content: center;
}

.supabase-auth-ui_ui-input, .supabase-auth-ui_ui-anchor {
  width: 50%;
}


.supabase-auth-ui_ui-label {
  text-align: left;
}


a:hover,
a:active {
  outline: 0;
}

button:focus {
  border: none;
  outline: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #272B34;
}

a:hover {
  text-decoration: none;
  color: #272B34;
}

a:focus {
  text-decoration: none;
  outline: none;
  color: #272B34;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #272B34;
  font-weight: 500;
}

h1 {
  font-size: 48px;
  line-height: 60px;
}

h2 {
  font-size: 36px;
  line-height: 44px;
}

h3,
.fc-center h2,
.error-comment {
  font-size: 30px;
  line-height: 40px;
}

h4 {
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
}

h5 {
  font-size: 18px;
  line-height: 26px;
  text-transform: capitalize;
}

h6,
.panel-title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

p {
  font-size: 14px;
  line-height: 25px;
}

p.lead {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 0;
  font-weight: 400;
}

p.text-muted {
  font-size: 14px;
}

.force-UTF-8 {
  content: "¡";
}

.nav {
  width:100%; 
  display:flex;
  justify-content:center;
  background-color:#fff;
  box-shadow: none;
  border-bottom: 1px solid #dee2e6;
}

.nav-wrap {
  width: 100%;
  max-width: 1400px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: right;
}

.horizontal-menu {
  padding: 0 15px 0 0;
  margin-bottom: 0;
  display:flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  width: 100%;
  max-width: 1400px;
}

.supabase-auth-ui_ui-button {
  background-color: #FF7545 !important;
  border-color:#f3f3f3 !important;
  color: #fff !important;
}

.supabase-auth-ui_ui-button:hover {
  background-color: #fff !important;
  color: #FF7545 !important;
}


/****************************/
/** Basic **/
/****************************/
.pa-0 {
  padding: 0px !important;
}

.pa-5 {
  padding: 5px !important;
}

.pa-10 {
  padding: 10px !important;
}

.pa-15 {
  padding: 15px !important;
}

.pa-20 {
  padding: 20px !important;
}

.pa-25 {
  padding: 25px !important;
}

.pa-30 {
  padding: 30px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.ma-0 {
  margin: 0px !important;
}

.ma-5 {
  margin: 5px !important;
}

.ma-10 {
  margin: 10px !important;
}

.ma-15 {
  margin: 15px !important;
}

.ma-20 {
  margin: 20px !important;
}

.ma-25 {
  margin: 25px !important;
}

.ma-30 {
  margin: 30px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.no-float {
  float: none !important;
}

.overflow-hide {
  overflow: hidden !important;
}

.clearfix {
  overflow: hidden;
  clear: both;
  float: none;
}

hr {
  margin-top: 10px;
  margin-bottom: 20px;
  border-top: 1px solid #727272;
}

.top-border {
  border-top: 1px solid #727272;
}

hr.light-grey-hr {
  border-top: 1px solid #eee;
}

.container {
  max-width: 1400px;
  width: 100%;
  background-color: #fff;
}


.container-login {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  padding: 25px;
  border: 1px solid #FF7545;
  border-radius: 25px;
}